import React from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";

import SvgButton from "./Custom/CustomButton";

import SchoolIcon from "@mui/icons-material/School";
import StarIcon from "@mui/icons-material/Star";
import WorkIcon from "@mui/icons-material/Work";
import accentureLogo from "../images/Accenture-logo.png";
import cognizantLogo from "../images/Cognizant.png";

import "../styles/timeLine.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TimeLine = () => {
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);

  const handleClose = () => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  return (
    <section id="timeline">
      <Box sx={{ backgroundColor: "#E3E3E3", paddingBottom: "20px" }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            color: "#ff4081",
            fontSize: "34px",
            textTransform: "none",
            paddingTop: "60px",
          }}
        >
          Work Experience & Education
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ paddingBottom: "60px" }}
        >
          Here's a glimpse of my education, qualifications and previous
          experience
        </Typography>

        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work "
            date="June 2023 - Present "
            dateClassName="timeLineDate"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              variant="h6"
              gutterBottom="true"
              sx={{ height: "28px" }}
            >
              Software Engineer Intern
            </Typography>
            <Typography id="company" variant="h2">
              Adori Labs, Inc
            </Typography>
            <Typography id="location" variant="subtitle1" gutterBottom="true">
              San Jose, California, USA
            </Typography>
            <br />
            {/* <Button variant="outlined" onClick={handleOpen1}>
              View More
            </Button> */}
            <SvgButton sx={{ letterSpacing: "1px" }} onClick={handleOpen1}>
              VIEW MORE
            </SvgButton>

            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Software Engineering Intern @ Adori Labs, Inc
                </Typography>
                <Typography variant="subtitle2" gutterBottom="true">
                  June 2023 - Present
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div>
                    <i>
                      <b>Technologies:</b> Python, FastAPI, LangChain, React,
                      Redux, JavaScript, AWS [Lambda, S3, DynamoDB, SageMaker,
                      CDN, SQS, SNS], GCP [GKE, OAuth], MySQL.
                    </i>
                  </div>
                  <ul>
                    <li>
                      Developed Content Authentication application leveraging
                      Adori's patented watermarking technology for audio &
                      video, scaling to 1M users.
                    </li>
                    <li>
                      Scaled file encoding service to encode 10x larger files,
                      55% faster, by designing a cost-effective serverless,
                      event-driven architecture, with distributed microservices
                      to split file & process parallelly using FastAPI, FFmpeg,
                      AWS Lambda, DynamoDB, and S3. (my article)
                    </li>
                    <li>
                      Built dashboards using modern React (Redux, Hooks) with
                      reusable components for file operations, progress bars,
                      notifications, tables etc.
                    </li>
                    <li>
                      Implemented asynchronous Text-to-Image REST APIs for
                      Adori’s Blog-to-Video service by integrating with
                      open-source Stable Diffusion LLM from HuggingFace, using
                      FastAPI, JWTs, OAuth2, AWS (Lambda, SageMaker). Reduced
                      OpenAI costs by 15%.
                    </li>
                    <li>
                      Built RAG based AI Chatbot using LangChain, OpenAI GPT-4
                      LLM, FastAPI handling 500+ user queries/day. Reduced
                      support tickets by 20%.
                    </li>
                    <li>
                      Implemented a token-based rate limiter service to limit
                      usage of AI integrated premium APIs. Optimized AWS
                      resource usage by 10%.
                    </li>
                    <li>
                      Monetized premium features, designing credit based in-app
                      purchases integrating with Square Payment API, boosting
                      revenue by 35%
                    </li>
                    <li>
                      Improved performance of existing microservices by
                      optimizing code, asynchronous method calls, memory caching
                      and implementing various design patterns such as
                      Singleton, Observer, Factory and Command pattern.
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education "
            date="Jan 2022 - Jan 2024"
            dateClassName="timeLineDate"
            // contentStyle={{ background: "#3F704D", color: "#fff" }}
            // contentArrowStyle={{ borderRight: "12px solid  brown" }}
            // iconStyle={{ background: "#3F704D", color: "#fff" }}
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<SchoolIcon />}
          >
            <Typography
              variant="h6"
              sx={{ height: "28px", display: { xs: "none", sm: "flex" } }}
            >
              Master of Science, Software Engineering
            </Typography>
            <Typography
              variant="h6"
              sx={{ height: "28px", display: { xs: "flex", sm: "none" } }}
            >
              M.S., Software Engineering
            </Typography>

            <Typography id="company" variant="h2">
              San Jose State University
            </Typography>

            <Typography id="location" variant="subtitle1" gutterBottom="true">
              San Jose, California, USA
            </Typography>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work "
            date="April 2019 - January 2022
            (2.75 years)"
            dateClassName="timeLineDate"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
          >
            <Typography
              variant="h6"
              sx={{ height: "28px" }}
              gutterBottom="true"
            >
              Full Stack Developer
            </Typography>

            <Typography id="company" variant="h2">
              Cognizant Technology Solutions
            </Typography>

            <Typography id="location" variant="subtitle1" gutterBottom="true">
              Bangalore, India
            </Typography>

            <br />
            <SvgButton sx={{ letterSpacing: "1px" }} onClick={handleOpen2}>
              VIEW MORE
            </SvgButton>
            <Modal
              open={open2}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Full Stack Developer at Cognizant
                </Typography>
                <Typography variant="subtitle2" gutterBottom="true">
                  April 2019 - January 2022
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div style={{ paddingTop: "10px" }}>
                    <i>
                      <b>Technologies:</b> Node.js, TypeScript, GraphQL,
                      React.js, Redux, MongoDB, Redis, Apache Kafka, Jenkins,
                      AWS, Jest, Mocha.
                    </i>
                  </div>
                  <ul>
                    <li>
                      Worked in Agile team to develop & maintain greenfield
                      Fleet Management B2B website in Node, React.js, that
                      scaled to 2M monthly users.
                    </li>
                    <li>
                      Integrated with diverse systems & data sources like ERP,
                      CAT fleet IoT hub, SOAs, middlewares across 10+ regions
                      for a mining industry client.
                    </li>
                    <li>
                      Designed 80+ low latency asynchronous REST & GraphQL APIs
                      on Node, implementing design patterns, data validation &
                      error handling.
                    </li>
                    <li>
                      Implemented secure, stateless APIs with authentication &
                      authorization using JWT, OAuth2 with Redis cache for token
                      validation.
                    </li>
                    <li>
                      Prototyped & built reusable dashboard components to manage
                      & monitor fleet telemetry data, inventory using React,
                      Chart.js, ElasticSearch.
                    </li>
                    <li>
                      Designed & implemented asynchronous, event-driven
                      microservices consuming Kafka broker topics. Gained 60%
                      faster message processing.
                    </li>
                    <li>
                      Evaluated access patterns & designed optimal MongoDB
                      schemas using Mongoose ORM, minimizing the number of
                      operations by 30%.
                    </li>
                    <li>
                      Wrote Unit & Integration tests using Jest, Mocha and
                      integrated with GitLab CI/CD pipeline ensuring 95%+
                      feature coverage.
                    </li>
                    <li>
                      Involved in complete SDLC process. Offered mentorship and
                      training to freshers on web development and best practices
                      in React.js.
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work "
            date="August 2016 - Febuary 2017
            (2.3  years)"
            dateClassName="timeLineDate"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<WorkIcon />}
            position="left"
          >
            <Typography variant="h6" sx={{ height: "28px" }}>
              Associate Software Developer
            </Typography>
            <Typography id="company" variant="h2">
              Accenture
            </Typography>

            <Typography id="location" variant="subtitle1" gutterBottom="true">
              Bangalore, India
            </Typography>

            <br />
            <SvgButton
              sx={{ letterSpacing: "1px" }}
              variant="outlined"
              onClick={handleOpen3}
            >
              VIEW MORE
            </SvgButton>
            <Modal
              open={open3}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Associate Software Developer at Accenture
                </Typography>
                <Typography variant="subtitle2" gutterBottom="true">
                  Mar 2017 – Apr 2019
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div style={{ paddingTop: "10px" }}>
                    <i>
                      <b>Technologies:</b> Java, Spring, Hibernate, Gradle,
                      React.js, Bootstrap, MySQL, Rabbit MQ, Git, Jenkins.
                    </i>
                  </div>
                  <ul>
                    <li>
                      Worked in Agile team to migrate and maintain Production
                      Planning application to MERN stack for Agro manufacturing
                      client based in US.
                    </li>
                    <li>
                      Modernized transport planning application with new
                      dashboards using React.js, Redux & Bootstrap enhancing
                      user experience.
                    </li>
                    <li>
                      Implemented 50+ key REST APIs on Spring Boot, integrating
                      with ERP, RabbitMQ and other business middlewares.
                    </li>
                    <li>
                      Built asynchronous scheduler microservice with UI using
                      Spring, Hibernate, React for scheduling jobs like reports,
                      monitoring tasks, alerts etc.
                    </li>
                    <li>
                      Implemented 100+ critical code changes involving feature
                      expansions, code optimization, async design patterns for
                      smoother operations.
                    </li>
                  </ul>
                </Typography>
              </Box>
            </Modal>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2012 - 2016"
            dateClassName="timeLineDate"
            // contentStyle={{
            //   background: "#3F704D",
            //   color: "#fff",
            //   paddingTop: "35px",
            //   paddingBottom: " 35px",
            // }}
            // contentArrowStyle={{ borderRight: "12px solid  brown" }}
            // iconStyle={{ background: "#3F704D", color: "#fff" }}
            iconStyle={{ background: "black", color: "#fff" }}
            icon={<SchoolIcon />}
            position="right"
          >
            <Link
              href="https://jssateb.ac.in/"
              sx={{ textDecoration: "none", color: "inherit" }}
              target="_blank"
              rel="noreferrer"
            >
              <Typography
                variant="h6"
                sx={{ height: "28px", display: { xs: "none", sm: "flex" } }}
              >
                Bachelor of Engineering, Computer Science
              </Typography>
              <Typography
                variant="h6"
                sx={{ height: "28px", display: { xs: "flex", sm: "none" } }}
              >
                B.Engg., Computer Science
              </Typography>
              <Typography id="company" variant="h2">
                Visvesvaraya Technological University
              </Typography>
              <Typography id="location" variant="subtitle1" gutterBottom="true">
                Bangalore, India
              </Typography>
            </Link>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<StarIcon />}
          />
        </VerticalTimeline>
      </Box>
    </section>
  );
};

export default TimeLine;
