import * as React from "react";

import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

const aboutStyle = {
  fontSize: "20px",
  textAlign: "justify",
};

const FullStory = () => {
  return (
    <section id="full-story">
      <Container
        maxWidth="md"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingBottom: "100px",
        }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            color: "#ff4081",
            fontSize: "34px",
            textTransform: "none",
            paddingTop: "60px",
          }}
        >
          About Me
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ paddingTop: "5px", paddingBottom: "60px" }}
        >
          More about me
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          display="block"
          sx={aboutStyle}
        >
          I am Sushmita Thrilochana, a passionate software engineer with over
          four years of experience specializing in full-stack development and
          cloud-native solutions. With a strong foundation in both frontend and
          backend technologies, I've successfully built and optimized scalable
          applications, integrating modern frameworks like React, FastAPI, and
          Spring Boot. My expertise lies in designing robust REST and GraphQL
          APIs, and I am well-versed in asynchronous programming,
          object-oriented design, and cloud platforms like AWS and GCP.
        </Typography>
        <br />
        <Typography
          variant="body1"
          gutterBottom
          display="block"
          sx={aboutStyle}
        >
          After completing my Bachelor's in Computer Science, back in India, I
          spent around four years at Accenture and Cognizant, where I worked on
          a production planning application for an agribusiness client and
          logistics planning application for a mining industry client. While
          working in various roles, I realized that to elevate my skills and
          become a more well-rounded software engineer, I needed to broaden my
          knowledge of complex systems, data-driven technologies, and
          cloud-native solutions. This realization led me to pursue a Master's
          degree in Software Engineering at San Jose State University. The
          program has allowed me to specialize in distributed systems and
          machine learning, giving me the technical foundation to tackle the
          most challenging problems in software development today.
        </Typography>
        <br />
        <Typography
          variant="body1"
          gutterBottom
          display="block"
          sx={aboutStyle}
        >
          Beyond coding, I'm enthusiastic about AI and data-driven solutions,
          always eager to explore new technologies and collaborate with teams to
          create impactful software. My journey in software engineering is
          driven by a continuous learning mindset, ensuring that I stay at the
          forefront of industry trends while delivering high-quality,
          maintainable code.
        </Typography>
      </Container>
    </section>
  );
};

export default FullStory;
