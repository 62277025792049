import * as React from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink, animateScroll as rsScroll } from "react-scroll";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Avatar, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SvgButton from "./Custom/CustomButton";

import "../styles/about.css";

import profilePic from "../images/profileCropped3.jpg";
import { Scale } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const aboutStyle = {
  fontSize: "20px",
  textAlign: "center",
};

const About = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path, id, offset) => {
    console.log("handleNavigarion in about", path, id, offset);
    if (path === location.pathname) {
      console.log(document.getElementById(id));
      rsScroll.scrollTo(document.getElementById(id).offsetTop - offset, {
        duration: 500,
        smooth: true,
      });
    } else {
      navigate(path, { state: { scrollToId: id } });
    }
  };

  React.useEffect(() => {
    const scrollToId = location.state?.scrollToId;
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        rsScroll.scrollTo(element.offsetTop - 100, {
          duration: 500,
          smooth: true,
        });
      }
    }
  }, [location]);

  return (
    <section id="about">
      <Box>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            color: "#ff4081",
            fontSize: "34px",
            textTransform: "none",
          }}
        >
          About Me
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ paddingBottom: "40px" }}
        >
          Passion meets Profession!
        </Typography>

        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{
              marginBottom: "25px",
              paddingRight: { md: "60px" },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <br />
              <Typography
                variant="body1"
                gutterBottom
                display="block"
                sx={aboutStyle}
              >
                I'm a passionate software engineer with 4+ years of experience
                in full-stack development and cloud-native solutions.
              </Typography>
              <br />
              <Typography
                variant="body1"
                gutterBottom
                display="block"
                sx={aboutStyle}
              >
                I excel in building scalable, efficient applications and thrive
                in fast-paced environments. With a strong focus on teamwork and
                continuous growth, I'm dedicated to delivering creative
                solutions that enhance user experiences.
              </Typography>
              <br />

              <br />

              <SvgButton
                sx={{
                  "--main-color": "darkslategrey",
                  letterSpacing: "1px",
                  "&:hover": {
                    "--main-color": "#ff4081",
                  },
                }}
                onClick={() =>
                  handleNavigation("/fullStory", "full-story", 100)
                }
              >
                FULL STORY
              </SvgButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Avatar
                className="avatar-hover-effect"
                sx={{
                  width: { xs: "300px", sm: "auto", lg: "300px" }, // Maintain aspect ratio by setting width to auto
                  height: "100%", // Set height to 100% of the Box or Grid
                  borderRadius: "50% 20%", // Keeps the oval shape
                  objectFit: "cover", // Ensures the image covers the Avatar area without distorting
                }}
                src={profilePic}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default About;
