import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProjectCards from "./ProjectCards/ProjectCards";

import appProjects from "../data/appProjects.json";
import mlProjects from "../data/mlProjects.json";

const Projects = () => {
  return (
    <section id="projects">
      <Box sx={{ paddingLeft: { sm: 4 }, marginTop: "30px" }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            color: "#ff4081",
            fontSize: "34px",
            textTransform: "none",
          }}
        >
          My Projects
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ paddingBottom: "40px" }}
        >
          Some of the things I've built
        </Typography>

        <Typography
          variant="h5"
          gutterBottom
          textAlign="center"
          sx={{
            color: "#ff4081",
            paddingBottom: "20px",
          }}
        >
          Full Stack Applications
        </Typography>
        <ProjectCards projects={appProjects} />

        <Typography
          variant="h5"
          gutterBottom
          textAlign="center"
          sx={{
            color: "#ff4081",
            paddingTop: "25px",
            paddingBottom: "15px",
          }}
        >
          Machine Learning Projects
        </Typography>
        <ProjectCards projects={mlProjects} />
      </Box>
    </section>
  );
};

export default Projects;
