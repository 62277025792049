import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Tooltip } from "@mui/material";
import linkedInIcon from "../images/linkedIn2.png";
import githubIcon from "../images/github-white.png";
import gmailIcon from "../images/gmail.png";
import { Link as ScrollLink, animateScroll as rsScroll } from "react-scroll";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import profileLinks from "../data/links.json";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const theme = createMuiTheme({
  palette: {
    buttonTheme: {
      orange: "#FE481D",
      pink: "#ff4081",
      main: "#ff4081",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1400,
      xl: 1536,
    },
  },
});

const pages = [
  { name: "About", id: "about", path: "/", offset: 100, navHoverOffset: 300 },
  { name: "Blogs", id: "blogs", path: "/blogs" },
  { name: "Skills", id: "skills", path: "/", offset: 100, navHoverOffset: 300 },
  {
    name: "Career Bio",
    id: "timeline",
    path: "/",
    offset: 60,
    navHoverOffset: 300,
  },
  {
    name: "Projects",
    id: "projects",
    path: "/",
    offset: 90,
    navHoverOffset: 320,
  },
  {
    name: "Resume",
    href: profileLinks.googleDriveResumeLink,
    target: "_blank",
    rel: "noreferrer",
    id: "resume",
  },
  {
    name: "Contact",
    id: "contact",
    path: "/",
    offset: 70,
    navHoverOffset: 500,
  },
];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [showName, setShowName] = useState(false);
  const [activeSection, setActiveSection] = useState(""); // Track the active section

  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigation = (path, id, offset) => {
    handleCloseNavMenu();

    if (path === location.pathname) {
      rsScroll.scrollTo(document.getElementById(id).offsetTop - offset, {
        duration: 500,
        smooth: true,
      });
    } else {
      setActiveSection(id);
      navigate(path, { state: { scrollToId: id } });
    }
  };

  // Handle scroll to target section on page load
  useEffect(() => {
    const scrollToId = location.state?.scrollToId;
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      if (element) {
        rsScroll.scrollTo(element.offsetTop - 100, {
          duration: 500,
          smooth: true,
        });
      }
    }
  }, [location]);

  // Handle scroll events and highlight nav buttons based on sections
  useEffect(() => {
    if (location.pathname === "/") {
      const handleScroll = () => {
        const sections = pages.filter((page) => page.id);
        let currentSection = "";

        for (const section of sections) {
          const element = document.getElementById(section.id);
          if (
            element &&
            window.scrollY >= element.offsetTop - section.navHoverOffset
          ) {
            currentSection = section.id;
          }
        }
        setActiveSection(currentSection);

        const showNameThreshold = 400;
        if (window.scrollY > showNameThreshold) {
          setShowName(true);
        } else {
          setShowName(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setShowName(true);
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" sx={{ backgroundColor: "#212529" }}>
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                fontSize: "32px",
                letterSpacing: ".1rem",
                color: "inherit",
                textDecoration: "none",
                minWidth: "150px",
                transition: "opacity 0.5s ease, visibility 0.5s ease",
                opacity: showName ? 1 : 0,
                visibility: showName ? "visible" : "hidden",
              }}
            >
              <ScrollLink
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                Sushmita
              </ScrollLink>
              <Box
                component="span"
                sx={{ color: theme.palette.buttonTheme.main }}
              >
                .
              </Box>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={() =>
                      handleNavigation(page.path, page.id, page.offset)
                    }
                  >
                    {page.href ? (
                      <Typography textAlign="center">
                        <a
                          href={page.href}
                          target={page.target}
                          rel={page.rel}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          {page.name}
                        </a>
                      </Typography>
                    ) : (
                      <Typography textAlign="center">{page.name}</Typography>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              {pages.map((page, index) => (
                <Button
                  key={index}
                  onClick={() =>
                    handleNavigation(page.path, page.id, page.offset)
                  }
                  href={page.href}
                  target={page.target}
                  rel={page.rel}
                  sx={{
                    my: "12px",
                    color: "white",
                    display: "block",
                    letterSpacing: 4,
                    fontSize: "14px",
                    mx: { lg: "12px", md: "4px" },
                    backgroundColor:
                      activeSection === page.id
                        ? theme.palette.buttonTheme.main
                        : "inherit",
                    "&:hover": {
                      backgroundColor: theme.palette.buttonTheme.main,
                    },
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="LinkedIn Profile">
                <IconButton
                  sx={{ marginRight: 1.5 }}
                  href="https://www.linkedin.com/in/sushmitha-dt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Avatar src={linkedInIcon} sx={{ borderRadius: "2px" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Github Profile">
                <IconButton
                  href="https://github.com/Sushmitha-93"
                  target="_blank"
                  rel="noreferrer"
                  sx={{ marginRight: 1.5 }}
                >
                  <Avatar src={githubIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy email ID">
                <IconButton
                  sx={{ marginRight: 1.5 }}
                  onClick={() =>
                    navigator.clipboard.writeText("sushmitha.dt@gmail.com")
                  }
                >
                  <Avatar src={gmailIcon} sx={{ borderRadius: "2px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default Navbar;
