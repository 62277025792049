import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";

import "./blogs.css";

import blogArticles from "../../data/blogArticles.json";

const Blogs = () => {
  return (
    <section id="blogs">
      <Container
        maxWidth="lg"
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            color: "#ff4081",
            fontSize: "34px",
            textTransform: "none",
            paddingTop: "60px",
          }}
        >
          My Blog Articles
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{ paddingTop: "5px", paddingBottom: "60px" }}
        >
          Exploring Tech, Life, and Everything in Between!
        </Typography>
        {blogArticles.map((article) => {
          //const articleImage = require(`../../data/img/${article.image}`);
          return (
            <Box sx={{ marginBottom: "40px" }}>
              <CardActionArea
                key={article.title} // Add a unique key for each item
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Initial shadow
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-5px)", // Moves the card slightly up
                    boxShadow: "0 0 10px #ff4081", // rgba(0, 123, 255, 0.7) Enhanced shadow on hover
                  },
                }}
              >
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column", // Stack content vertically on extra small screens
                      md: "row", // Keep it horizontal for small screens and above
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      flex: 1,
                      width: {
                        xs: "100%", // Full width on extra small screens
                        md: 400, // Fixed width on small screens and above
                      },
                      padding: "10px",
                      boxSizing: "border-box", // Include padding in width
                    }}
                    image={article.image}
                    alt={article.imageAlt}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        sx={{ paddingTop: "6px" }}
                      >
                        {article.title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="div"
                        sx={{ color: "text.secondary" }}
                      >
                        {article.date}
                      </Typography>
                      <Typography
                        variant="body1"
                        component="div"
                        sx={{ paddingTop: "30px" }}
                      >
                        {article.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        target="_blank"
                        rel="noreferrer"
                        href={article.link}
                        sx={{ marginBottom: "10px" }}
                      >
                        Learn More
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
              </CardActionArea>
            </Box>
          );
        })}
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "50px",
            color: "#2F3740",
            fontSize: "20px",
          }}
        >
          More articles coming soon!
        </Typography>
      </Container>
    </section>
  );
};

export default Blogs;
