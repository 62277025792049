import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container } from "@mui/material";

import About from "./components/About";
import Navbar from "./components/Navbar";
import TimeLine from "./components/TimeLine";
import appTheme from "./theme.js";
import ResumeHomeBlock from "./components/ResumeHomeBlock/ResumeHomeBlock";
import Projects from "./components/Projects";
import Contact from "./components/Contact/Contact";
import Skills from "./components/Skills";
import Blogs from "./components/BlogsPage/Blogs"; // Import the new Blog component
import FullStory from "./components/FullStory.jsx";
import OpenToWork from "./components/OpenToWork.jsx";
import Footer from "./components/Footer.jsx";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <React.Fragment>
              <ResumeHomeBlock
                shortFullName={"Sushmitha"}
                headline={"Hello"}
                style={appTheme.python.style}
              />
              <Container maxWidth="lg" sx={{ marginTop: 5 }}>
                <About />
                <OpenToWork />
                <Skills />
              </Container>
              <TimeLine />
              <Projects />
              <Contact />
            </React.Fragment>
          }
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/fullStory" element={<FullStory />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
