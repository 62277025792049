import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa"; // Importing React Icons

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#212529", // Background matches your website's professional tone
        color: "#fff",
        padding: "30px 0", // Padding at the top for spacing
        textAlign: "center",
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Left Side: Social Icons */}
        <Grid item xs={12} md={4} container justifyContent="center">
          <a
            href="https://www.linkedin.com/in/sushmitha-dt/"
            target="_blank"
            rel="noreferrer"
            style={{ margin: "0 10px", color: "#fff" }} // Standard white color for professional look
          >
            <FaLinkedin
              style={{
                fontSize: "24px",
                borderRadius: "50%",
                padding: "5px",
                backgroundColor: "#0077B5",
              }}
            />
          </a>
          <a
            href="https://github.com/Sushmitha-93"
            target="_blank"
            rel="noreferrer"
            style={{ margin: "0 10px", color: "#fff" }}
          >
            <FaGithub
              style={{
                fontSize: "24px",
                borderRadius: "50%",
                padding: "5px",
                backgroundColor: "#333",
              }}
            />
          </a>
          <a
            href="mailto:sushmitha.dt@gmail.com"
            style={{ margin: "0 10px", color: "#fff" }}
          >
            <FaEnvelope
              style={{
                fontSize: "24px",
                borderRadius: "50%",
                padding: "5px",
                backgroundColor: "#EA4335",
              }}
            />
          </a>
        </Grid>

        {/* Middle Section */}
        <Grid
          item
          xs={12}
          md={4}
          container
          justifyContent="center"
          direction="column"
        >
          {/* Footer Message */}
          <Typography
            variant="body2"
            sx={{
              color: "#b3b3b3", // Subtle, professional color
              fontSize: "14px", // Standard font size for a footer
            }}
          >
            © {new Date().getFullYear()} Sushmita . All rights reserved | "Plant
            Trees Save Lives"
          </Typography>

          {/* Built with React.js */}
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={4} container justifyContent="center">
          <Typography
            variant="body2"
            sx={{
              color: "#b3b3b3", // Subtle, professional color
              fontSize: "14px", // Standard font size for a footer
            }}
          >
            Built with React.js&nbsp;
          </Typography>
          <Box>
            <i
              className="devicon-react-original colored"
              style={{ fontSize: "17px" }}
            ></i>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
