import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/system";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Grid, IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material-next/Button";

import SvgButton from "../Custom/CustomButton";

import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import profileLinks from "../../data/links.json";

import "./Contact.css";

const Contact = () => {
  return (
    <section id="contact">
      <Box sx={{ backgroundColor: "#E3E3E3", paddingBottom: "40px" }}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            color: "#ff4081",
            fontSize: "34px",
            textTransform: "none",
            paddingTop: "60px",
          }}
        >
          Looking Forward to Collaborate?
        </Typography>

        <Container maxWidth="sm" sx={{ marginTop: 2 }}>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{ fontSize: "18px" }}
          >
            Feel free to mail me! My inbox is always open whether you have a
            question or just want to say hello!
          </Typography>
        </Container>
        <Container maxWidth="sm" sx={{ marginTop: "10px" }}>
          <Grid container justifyContent="center">
            <Grid item>
              <EmailIcon
                sx={{
                  color: "#F14336",
                  fontSize: "30px",
                  marginTop: "11px",
                  marginRight: "9px",
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                textAlign="center"
                sx={{
                  color: "#283593",
                  fontSize: "23px",
                  marginTop: "8px",
                }}
              >
                sushmitha.dt@gmail.com
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Copy Email">
                <IconButton
                  sx={{
                    color: "#ff4081",
                    fontSize: "20px",
                    marginTop: "6px",
                    marginLeft: "3px",
                  }}
                >
                  <ContentCopyIcon
                    onClick={() =>
                      navigator.clipboard.writeText("sushmitha.dt@gmail.com")
                    }
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Container>
        <Typography
          variant="h2"
          textAlign="center"
          gutterBottom
          sx={{
            color: "#ff4081",
            fontSize: "25px",
            textTransform: "none",
            paddingTop: "27px",
            marginBottom: "10px",
          }}
        >
          Get in Touch!
        </Typography>
        <Container maxWidth="md">
          <Grid container justifyContent="center">
            <Grid item>
              <IconButton
                href="https://www.linkedin.com/in/sushmitha-dt/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon
                  sx={{
                    color: "#0274B3",
                    fontSize: "70px",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                href="https://github.com/Sushmitha-93"
                target="_blank"
                rel="noreferrer"
                sx={{ paddingLeft: "0px" }}
              >
                <GitHubIcon
                  sx={{
                    fontSize: "60px",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item sx={{ paddingTop: "17px", paddingLeft: "8px" }}>
              <SvgButton
                sx={{
                  "--main-color": "darkslategrey",
                  "--hover-color": "#DADADA",
                  letterSpacing: "2px",
                  "&:hover": {
                    "--main-color": "#ff4081",
                  },
                }}
                href={profileLinks.googleDriveResumeLink}
                target="_blank"
              >
                RESUME
              </SvgButton>
              {/* <Button
                href={profileLinks.googleDriveResumeLink}
                target="_blank"
                rel="noreferrer"
                startIcon={<ContactPageIcon />}
                color="secondary"
                size="large"
                variant="filled"
                sx={{ fontSize: "20px" }}
              >
                Resume
              </Button> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </section>
  );
};

export default Contact;
