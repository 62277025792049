import React from "react";
import PropTypes from "prop-types";

import GridBackground from "../../components/GridBackground/GridBackground";
import ScreenBlock from "../../components/ScreenBlock/ScreenBlock";

import appTheme from "../../theme";
import "./ResumeHomeBlock.css";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";

import { HiSpeakerWave } from "react-icons/hi2";
import { IconButton, Tooltip } from "@mui/material";
import nameAudio from "../../audio/sushmitha.mp3";

import { IoIosArrowDown } from "react-icons/io";
import { PiCaretDownBold } from "react-icons/pi";

const theme = createMuiTheme({
  palette: {
    buttonTheme: {
      orange: "#FE481D", // old
      pink: "#ff4081", // new
      main: "#ff4081", // Default main color (orange)
    },
  },
});

const scrollToNextSection = () => {
  const nextSection = document.getElementById("about");
  if (nextSection) {
    const offset = 100; // The offset value
    const sectionPosition =
      nextSection.getBoundingClientRect().top + window.scrollY - offset;

    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }
};

export const ResumeHomeBlock = ({ style, shortFullName, headline }) => {
  function playAudio() {
    new Audio(nameAudio).play();
  }

  return (
    <section id="home">
      <ScreenBlock id="Resume-home" style={style} className="ResumeHomeBlock">
        <div className="ResumeHomeBlock-headline-container">
          <div className="ResumeHomeBlock-headline">
            <Typography variant="h2" sx={{ textAlign: "left" }}>
              Hey there! I am
            </Typography>
            <Typography variant="h1">
              Sushmita
              <Box
                component="span"
                sx={{ color: theme.palette.buttonTheme.main }}
              >
                .
              </Box>
              <Tooltip title="Click for pronunciation">
                <IconButton
                  className="speakerButton"
                  sx={{
                    width: { xs: 30, sm: 40, md: 50 }, // Adjust the size based on the screen size
                    height: { xs: 30, sm: 40, md: 50 },
                  }}
                >
                  <HiSpeakerWave size="100%" onClick={playAudio} />
                </IconButton>
              </Tooltip>
            </Typography>
            <Typography variant="h2">
              Full-stack Web Developer{" "}
              <span
                style={{
                  color: theme.palette.buttonTheme.main,
                  fontWeight: "bold",
                }}
              >
                |
              </span>{" "}
              Microservices
            </Typography>
          </div>
        </div>

        <div className="ResumeHomeBlock-squares">
          <GridBackground>
            <div style={{ ...appTheme.java.style }}>
              {appTheme["java"].icon}
            </div>
            <div style={{ ...appTheme.python.style }}>
              {appTheme.python.icon}
            </div>
            <div style={{ ...appTheme.reactColor.style }}>
              {appTheme.reactColor.icon}
            </div>
            <div style={{ ...appTheme.graphql.style }}>
              {appTheme.graphql.icon}
            </div>
            <div style={{ ...appTheme.javascriptColor.style }}>
              {appTheme.javascriptColor.icon}
            </div>
            <div style={{ ...appTheme.dockerColor.style }}>
              {appTheme.dockerColor.icon}
            </div>
          </GridBackground>
        </div>

        {/* Add floating animated down arrow button */}
        <div className="floating-arrow" onClick={scrollToNextSection}>
          <IoIosArrowDown size={80} />
        </div>
      </ScreenBlock>
    </section>
  );
};

ResumeHomeBlock.propTypes = {
  headline: PropTypes.string.isRequired,
  shortFullName: PropTypes.string.isRequired,
  style: PropTypes.object,
};

ResumeHomeBlock.defaultPropTypes = {
  style: {},
};

export default ResumeHomeBlock;
